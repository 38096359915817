.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: opacity 0.3s ease-in-out;
}

.popupContent {
    background-color: var(--color-background);
    padding: 30px;
    width: 400px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    position: relative;
    transition: transform 0.3s ease-in-out;
    transform: scale(1);
}

.button{
    font-family: "Cubano Normal",sans-serif;
    background-color: var(--color-350);
    color:var(--color-background);
    cursor: pointer;
    padding: 0 10px;
    font-size: 20px;
    border:none;
    transition: .2s;
    height: 40px;
    outline: none;
    border-radius: var(--border-radius);
    width: min(400px, 100%);
}

.button:hover{
    background-color:var(--color-400)
}
.input{
    font-size: 40px;
    margin-bottom:20px;
    margin-top: 10px;
    padding: 0 20px 0 10px;
    height: 40px;
    outline: 0;
    border: none;
    /*border-radius: var(--border-radius);*/
    font-family: "Roboto Mono",sans-serif;
    color: var(--color-450);
    background-color: var(--color-background);
    /*box-shadow: 0 3px 10px rgba(0, 0, 0, 0.56) inset;*/
    /*buttom line*/
    border-bottom: 2px solid var(--color-300);
    width: calc(100% - 30px);
    text-align: center;
    transition: all 0.2s;
}
.input::placeholder{
    color: var(--color-350);
}

.input:focus{
    outline: 0;
    border-bottom: 2px solid var(--color-400);
    /*box-shadow: 0 3px 30px rgba(0, 0, 0, 0.56) inset;*/
}