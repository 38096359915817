.header{
    font-family: "Cubano Normal",sans-serif;
    padding: 50px 20px;
    margin: auto;
    max-width:800px ;


}
.top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.header svg{
    fill:var(--color-350);
    height: 25px;
    cursor: pointer;
    margin-right: 10px;
    transition: 0.2s;
}
.header svg:hover{
    fill:var(--color-400)
}


.left__bar{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.left__bar h1{
    font-size: 40px;
    margin-right: 20px;
    color:var(--color-350);
    user-select: none;
}


.right__bar{
    display: flex;
    align-items: center;
    flex-wrap: wrap;

}
.right__bar button{
    font-family: "Cubano Normal",sans-serif;
    background-color: var(--color-350);
    color:var(--color-background);
    padding: 10px 30px;
    font-size: 20px;
    border:none;
    border-radius: var(--border-radius);
    cursor: pointer;
    margin-right: 40px;
    transition: .2s;
}
.right__bar button:hover{
    background-color:var(--color-400)
}
.right__bar p{
    user-select: none;
    font-size: 20px;
    margin-right: 20px;
    color:var(--color-350);
    cursor: pointer;
    transition: .2s color;
}
.right__bar:hover svg,
.right__bar:hover p{
    fill:var(--color-400);
    color:var(--color-400)
}

.settings{
    color:var(--color-350);
}
.settings span{
    font-size: 16px;
    padding-right: 5px;
    cursor: default;
    transition: color .2s;
}
.isAuth span{
    cursor: pointer;
}
.selected,
.isAuth span:hover,
.settings span:focus-visible{
    color: var(--color-450);
    cursor: pointer;
}

.flex{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.user__name{
    color: var(--color-350);
}

@media (max-width: 600px) {
    .left__bar{
        margin: 10px 0 ;
    }

}
.web{

}
.settings{
    margin: 0 3px 0 -5px!important;
}
.lock{
    height: 20px!important;
}