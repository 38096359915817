.footer{
    position: fixed;
    inset: auto 0 20px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.footer *{
    transition: color 0.2s;
}
.footer a{
    text-decoration: none;
}
.footer div{
    display: flex;
    align-items: center;
    margin-right: 30px;
    cursor: pointer;
}
.footer div>p{
    color: var(--color-350);
    font-family: "Roboto Mono",sans-serif;
    margin-left: 10px;
}
.footer{
    opacity: 0.8;
}
/*.mail__wrapper svg,*/
/*.code__wrapper svg{*/
/*    fill: var(--color-footer);*/
/*}*/
.mail__wrapper svg,
.materials__wrapper svg{
    fill:none;
    stroke: var(--color-350);
}
.footer div:hover p,

.mail__wrapper:hover svg,
.code__wrapper:hover svg{
    color: var(--color-400);
    fill: var(--color-400);
}
.mail__wrapper:hover svg,
.materials__wrapper:hover svg{
    fill:none;
    stroke:var(--color-400);
}
