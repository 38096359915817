
@font-face {
    font-family: 'Roboto Mono';
    src: local('Roboto Mono'), local('RobotoMono-Regular'), url('Robotomono.woff2') format('woff2'), url('Robotomono.woff') format('woff'), url('Robotomono.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Cubano Normal';
    src:  url('./webfonts/cubano-regular-webfont.ttf') format('truetype'), url('./webfonts/cubano-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
