.settings{
    font-size: 40px;
    margin-top: 30px;
}
.item{
    margin-bottom: 20px;

}
@media (min-width: 800px) {
    .item{
        display: flex;
        justify-content: space-between;
    }
}
.customTextArea{
    resize: vertical;
    max-height: 400px;
    height: 100px;
    margin-left: 10px;
    padding: 20px;
}
