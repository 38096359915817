.Ocean{
    --color-300: #356578;
    --color-350:#43819A;
    --color-400: #62a8c2;
    --color-450:#65BAD4;
    --color-text-correct:#FF6A00;
    --color-text-error: #900400;
    --color-text-nottyped:var(--color-350);
    --color-background: #193e4e;
    --color-800: #d5ac00;
    --background-gradient:linear-gradient(65.59deg, #0F2027 10.87%, #274957 102.25%) no-repeat;
}
.Matrix{
    --color-300: var(--color-350);
    --color-350: #40ad43;
    --color-400: #00ff39;
    --color-450: #02ff19;
    --color-text-correct:var(--color-450);
    --color-text-error: #900400;
    --color-text-nottyped:var(--color-350);
    --color-background: #091418;
    --color-800: #d5ac00;
    --background-gradient:linear-gradient(65.59deg, #0F2027 10.87%, #102123 102.25%) no-repeat;
}
.Pink{
    --color-300: var(--color-350);
    --color-350: #FFC4D0;
    --color-400: #F7DDDE;
    --color-450: #FCF5EE;
    --color-text-correct:var(--color-450);
    --color-text-error: #cd0c07;
    --color-text-nottyped:var(--color-350);
    --color-background: #c797a1;
    --color-800: #749fff;
    --background-gradient:linear-gradient(65.59deg, #6c4560 10.87%, #6c4161 102.25%) no-repeat;
}