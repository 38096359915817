


.empty{
    font-family: "Cubano Normal",sans-serif;
    font-size: 40px;
    margin-right: 20px;
    color:var(--color-350);
    transition: .2s color;
    padding: 0 0 0 10px;
    opacity: 0.5;
}
.rooms__online{
    font-family: "Cubano Normal",sans-serif;
    font-size: 20px;
    margin-right: 20px;
    color:var(--color-350);
    transition: .2s color;
    padding: 0 0 0 10px;
    opacity: 0.5;
    margin-bottom: 10px;
}