
.text__wrapper{
    margin-top: 50px;
    position: relative;
}
.text{
    position: relative;
    color: var(--color-text-correct);
    font-size: 24px;
    font-family:"Roboto Mono" ,sans-serif;
    line-height: 38px;
    letter-spacing: 0.5px;
    word-break: break-word;
    /*background-image: var(--text-gradient-background);*/
    /*-webkit-background-clip: text;*/
}
.input__for__phones{
    position: absolute;
    inset: 0;
    opacity: 0;
}
@media not handheld {
    .input__for__phones{
        display: none;
    }
}
.cursor{
    position: absolute;
    top: 4px;
    left:0;
    width: 3px;
    height: 30px;
    background-color: var(--color-800);
    transition: left 0.08s ease-out, top 0.08s ease-out;
    border-radius: var(--border-radius);

}
.user__cursor{
    z-index: 2;
    composes: cursor;
    background-color: var(--color-450);
    transition: 0.5s;

}
.user__cursor+p{
    font-size: 16px;
    font-family:"Roboto Mono" ,sans-serif;
    position: absolute;
    color: var(--color-450);
    bottom: 25px;
    left: 5px;
    width:auto;
    transition: 1s;

}
.disabled__letter{
    -webkit-text-fill-color: initial;
    color: var(--color-text-nottyped);
}
/*.right__letter{*/
    /*-webkit-text-fill-color: transparent;*/
/*}*/
.mistake__letter{
    -webkit-text-fill-color: initial;
    color: var(--color-text-error);
    position: relative;
}
.underline{
    text-decoration: underline;
    z-index: -1;
}
/*.mistake__letter:before{*/
/*    content: '';*/
/*    position: absolute;*/
/*    inset: 30px 0 0 0;*/
/*    background-color: var(--color-text-error);*/
/*    animation: appear ease-in-out forwards 100ms;*/
/*    z-index: -1;*/
/*}*/
/*@keyframes appear {*/
/*    from{*/
/*        inset: 25px 16px 2px 0;*/
/*    }*/
/*    to{*/
/*        inset: 25px 0 2px 0;*/
/*    }*/
/*}*/