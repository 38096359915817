

body {
  margin: 0;
  font-family: 'Cubano Normal' ,sans-serif;
  background:var(--background-gradient) ;
  background-size: cover;
  min-height: 100vh;
  box-sizing: border-box;
  color:var(--color-350);
  --border-radius:10px;
}


*{
  transition: color 0.05s,fill 0.05s;
  margin: 0;
}
svg{
  fill:var(--color-350);
}
svg:hover{
  fill:var(--color-450);
}
.input,textarea{
  padding: 0 20px;
  height: 40px;
  outline: none;
  border: none;
  border-radius: var(--border-radius);
  font-family: "Roboto Mono",sans-serif;
  color: var(--color-450);
  background-color: var(--color-background);
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.56) inset;
  width: calc(100% - 40px);
}
textarea::-webkit-scrollbar {
  width: .5em;
  border-radius: 100px;
  background-color: var(--color-background);
}
textarea::-webkit-scrollbar-thumb{
  background-color: var(--color-350);
  border-radius: 100px;
}
textarea::-webkit-scrollbar-corner{
  outline: none;
  background-color: var(--color-350);
  border-radius: 100px;
}
.input:-webkit-autofill,
.input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
.input::placeholder{
  color: var(--color-350);
}
