
.themes p{
    font-family: "Cubano Normal",sans-serif;
    user-select: none;
    font-size: 40px;
    margin-right: 20px;
    color:var(--color-350);
    cursor: pointer;
    transition: .2s color;
    padding: 0 0 0 10px;
}

.themes p:hover{
    fill:var(--color-400);
    color:var(--color-400)
}
.point{
    transition: background-color 0.2s;
    border-radius: var(--border-radius);
}
.point:hover{
    background-color: rgba(255, 255, 255, 0.05);
}